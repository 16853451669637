.button-block {
  @include button-block;
  @include button-block-blue;

  &.button-blue {
    @include button-block-blue;
  }

  &.button-hover-dark-blue {
    @include button-block-hover-dark-blue;
  }

  &.button-gray {
    @include button-block-gray;
  }

  &.button-black-af {
    @include button-block-black-af;
  }

  &.button-white-outline {
    @include button-block-white-outline;
  }

  &.button-black-outline {
    @include button-block-black-outline;
  }

  &.button-black-outline-thin {
    @include button-block-black-outline-thin;
  }
}