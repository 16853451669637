.icon-cars {
  content: url("icon-cars.7894d9d9.svg");
}

.icon-stats {
  content: url("icon-stats.55a0a95a.svg");
}

.icon-settings {
  content: url("icon-settings.f4bcf41b.svg");
}

.icon-check {
  content: url("check.05fed190.svg");
}

.icon-plus-bg-white {
  content: url("icon-plus-bg-white.57bd3cb9.svg");
}

*, :before, :after {
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

body, p, a, input, select, textarea, button, ul, ol, li {
  font-family: BMWTypeNextWeb, Arial, Helvetica, sans-serif;
}

.container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
}

section {
  margin-bottom: 40px;
}

@media (min-width: 769px) {
  section {
    margin-bottom: 90px;
  }
}

.section-black {
  color: #fff;
  background: #0d0d0d;
}

.section-beige {
  background: #eee4d4;
}

.section-gray {
  background: #f2f5f7;
}

.section-gray-light {
  background: #f5f6f7;
}

.section-gray-lg {
  background: #e6e6e6;
}

.section-blue-lg {
  background: #f4f8fb;
}

.section-gray-dark {
  background: #1d1d1d;
}

.section-radial-dark {
  background-image: radial-gradient(circle at 44% 83%, #2a5d3a, #000, #616971, #69727b 0%, #152320 99%, #30312b 99%);
}

.s-padding-50 {
  padding: 30px 0;
}

@media (min-width: 769px) {
  .s-padding-50 {
    padding: 50px 0;
  }
}

.s-padding-60 {
  padding: 40px 0;
}

.s-padding-60:first-child {
  padding-top: 0;
}

.s-padding-60 + .s-padding-60 {
  padding-bottom: 0;
}

@media (min-width: 769px) {
  .s-padding-60 {
    padding: 60px 0;
  }
}

.s-padding-70 {
  padding: 40px 0;
}

@media (min-width: 769px) {
  .s-padding-70 {
    padding: 70px 0;
  }
}

.s-padding-80 {
  padding: 50px 0;
}

@media (min-width: 769px) {
  .s-padding-80 {
    padding: 80px 0;
  }
}

.s-padding-90 {
  padding: 50px 0;
}

@media (min-width: 769px) {
  .s-padding-90 {
    padding: 90px 0;
  }
}

.s-padding-100 {
  padding: 60px 0;
}

@media (min-width: 769px) {
  .s-padding-100 {
    padding: 100px 0;
  }
}

.text-light {
  font-weight: 300;
}

.text-black {
  color: #000;
}

.text-white {
  color: #fff;
}

.h2-center h2 {
  text-align: center;
}

.h2-right h2 {
  text-align: right;
}

.h2-33 h2 {
  letter-spacing: .5px;
  margin: 0 0 32px;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.29;
}

@media (min-width: 769px) {
  .h2-33 h2 {
    letter-spacing: .8px;
    font-size: 33px;
  }
}

.h3-24 h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: .96;
}

.h2-uppercase h2 {
  text-transform: uppercase;
}

.button-center {
  text-align: center;
}

.button-left {
  text-align: left;
}

.button-right {
  text-align: right;
}

.nm {
  margin: 0;
}

.f-xl {
  text-transform: none;
  font-size: 36px;
  line-height: 1;
}

.f-xl-subtitle {
  margin: 0 0 30px;
  font-size: 18px;
}

@media (min-width: 769px) {
  .f-xl-subtitle {
    font-size: 18px;
  }
}

.image-round img {
  border-radius: 6px;
}

@media (min-width: 769px) {
  .image-round-desktop img {
    border-radius: 6px;
  }
}

@media (max-width: 768px) {
  .image-round-mobile img {
    border-radius: 6px;
  }
}

.at-bottom:before, .at-bottom:after {
  top: 100%;
}

.at-bottom:after {
  border-radius: 20px 20px 0 0;
}

@media (min-width: 1240px) {
  .at-bottom:after {
    border-radius: 40px 40px 0 0;
  }
}

.at-bottom.at-inner {
  padding-bottom: 40px;
}

@media (min-width: 1240px) {
  .at-bottom.at-inner {
    padding-bottom: 80px;
  }
}

.at-bottom.at-inner:before, .at-bottom.at-inner:after {
  margin-top: -40px;
}

@media (min-width: 1240px) {
  .at-bottom.at-inner:before, .at-bottom.at-inner:after {
    margin-top: -80px;
  }
}

.at-bottom.at-inner:after {
  border-radius: 0 0 20px 20px;
}

@media (min-width: 1240px) {
  .at-bottom.at-inner:after {
    border-radius: 0 0 40px 40px;
  }
}

.at-top:before, .at-top:after {
  bottom: 100%;
}

.at-top:after {
  border-radius: 0 0 20px 20px;
}

@media (min-width: 1240px) {
  .at-top:after {
    border-radius: 0 0 40px 40px;
  }
}

.at-top.at-inner {
  padding-top: 40px;
}

@media (min-width: 1240px) {
  .at-top.at-inner {
    padding-top: 80px;
  }
}

.at-top.at-inner:before, .at-top.at-inner:after {
  margin-bottom: -40px;
}

@media (min-width: 1240px) {
  .at-top.at-inner:before, .at-top.at-inner:after {
    margin-bottom: -80px;
  }
}

.at-top.at-inner:after {
  border-radius: 20px 20px 0 0;
}

@media (min-width: 1240px) {
  .at-top.at-inner:after {
    border-radius: 40px 40px 0 0;
  }
}

.round-black, .round-gray-light, .round-gray-dark, .round-white {
  position: relative;
}

.round-black:before, .round-black:after, .round-gray-light:before, .round-gray-light:after, .round-gray-dark:before, .round-gray-dark:after, .round-white:before, .round-white:after {
  content: "";
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  left: 0;
}

@media (min-width: 1240px) {
  .round-black:before, .round-black:after, .round-gray-light:before, .round-gray-light:after, .round-gray-dark:before, .round-gray-dark:after, .round-white:before, .round-white:after {
    height: 80px;
  }
}

.round-black:before, .round-gray-light:before, .round-gray-dark:before, .round-white:before {
  transition: background .4s ease-in-out;
}

.round-black:before {
  background: #0d0d0d;
}

.round-black:after, .round-white:before {
  background: #fff;
}

.round-white:after {
  background: #0d0d0d;
}

.round-gray-dark:before {
  background: #fff;
}

.round-gray-dark:after {
  background: #1d1d1d;
}

.round-gray-light:before {
  background: #fff;
}

.round-gray-light:after {
  background: #f5f6f7;
}

.bg-from-beige:before {
  background: #eee4d4;
}

@media (max-width: 768px) {
  .only-desktop {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .only-mobile {
    display: none !important;
  }
}

.hide {
  display: none;
}

.col-50 {
  width: 50%;
}

@media (max-width: 768px) {
  .col-50 {
    width: 100%;
  }
}

.col-70 {
  width: 70%;
}

@media (max-width: 768px) {
  .col-70 {
    width: 100%;
  }
}

.col-30 {
  width: 30%;
}

@media (max-width: 768px) {
  .col-30 {
    width: 100%;
  }
}

@font-face {
  font-family: BMWGroup_Global;
  src: url("BMWGroup_Global_Thin.cc6b17e6.woff2") format("woff2"), url("BMWGroup_Global_Thin.fe2c417c.woff") format("woff"), url("BMWGroup_Global_Thin.90b9c1c1.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: BMWGroup_Global;
  src: url("BMWGroup_Global_Light.af394a1b.woff2") format("woff2"), url("BMWGroup_Global_Light.3ffb38ea.woff") format("woff"), url("BMWGroup_Global_Light.f8ba61bb.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: BMWGroup_Global;
  src: url("BMWGroup_Global_Bold.9604cf18.woff2") format("woff2"), url("BMWGroup_Global_Bold.57cbfa90.woff") format("woff"), url("BMWGroup_Global_Bold.9d0b184b.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: BMWTypeNextWeb;
  src: url("BMWTypeNext-Thin.0ca56679.woff2") format("woff2"), url("BMWTypeNextTT-Thin.58dbef92.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: BMWTypeNextWeb;
  src: url("BMWTypeNext-Light.170c62c5.woff2") format("woff2"), url("BMWTypeNextTT-Light.e2e248f4.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: BMWTypeNextWeb;
  src: url("BMWTypeNext-Regular.573e756b.woff2") format("woff2"), url("BMWTypeNextTT-Regular.10607051.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: BMWTypeNextWeb;
  src: url("BMWTypeNext-Bold.df9773be.woff2") format("woff2"), url("BMWTypeNextTT-Bold.d0452434.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

h2 {
  font-weight: 300;
}

.button-block {
  box-shadow: none;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  background: #1c69d4;
  border: 1px solid #1c69d4;
  border-radius: 0;
  outline: none;
  width: 100%;
  max-width: 300px;
  padding: 13px 10px;
  line-height: 1.4;
  text-decoration: none;
  transition: all .3s linear;
  display: inline-block;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.button-block:focus, .button-block:hover {
  background: #fff;
  text-decoration: none;
  color: #1c69d4 !important;
}

.button-block.button-blue {
  background: #1c69d4;
  border: 1px solid #1c69d4;
  color: #fff !important;
}

.button-block.button-blue:focus, .button-block.button-blue:hover {
  background: #fff;
  color: #1c69d4 !important;
}

.button-block.button-hover-dark-blue:focus, .button-block.button-hover-dark-blue:hover {
  background: #0653b6;
  border: 1px solid #0653b6;
  color: #fff !important;
}

.button-block.button-gray {
  background: #4d4d4d;
  border: 1px solid #4d4d4d;
  color: #fff !important;
}

.button-block.button-gray:focus, .button-block.button-gray:hover {
  background: #fff;
  color: #4d4d4d !important;
}

.button-block.button-black-af {
  background: #0d0d0d;
  border: 1px solid #0d0d0d;
  color: #fff !important;
}

.button-block.button-black-af:focus, .button-block.button-black-af:hover {
  background: #fff;
  color: #0d0d0d !important;
}

.button-block.button-white-outline {
  background: none;
  border: 2px solid #fff;
  color: #fff !important;
}

.button-block.button-white-outline:focus, .button-block.button-white-outline:hover {
  background: none;
  border: 2px solid #8e8e8e;
  color: #8e8e8e !important;
}

.button-block.button-black-outline {
  background: none;
  border: 2px solid #000;
  color: #000 !important;
}

.button-block.button-black-outline:focus, .button-block.button-black-outline:hover {
  background: #000;
  border: 2px solid #000;
  color: #fff !important;
}

.button-block.button-black-outline-thin {
  background: none;
  border: 1px solid #000;
  color: #000 !important;
}

.button-block.button-black-outline-thin:focus, .button-block.button-black-outline-thin:hover {
  background: none;
  border: 1px solid #000;
  box-shadow: inset 1px 1px #000, inset -1px -1px #000;
  color: #000 !important;
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 .25em .5em #0000001a;
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide__bullet--active {
  background-color: #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.section-text :first-child {
  margin-top: 0;
}

.section-text :last-child {
  margin-bottom: 0;
}

.usps-block-with-icons {
  text-align: center;
  background: #cfdceb;
}

@media (max-width: 768px) {
  .usps-block-with-icons {
    margin: 0 -20px;
    padding: 30px 20px;
  }
}

@media (min-width: 769px) {
  .usps-block-with-icons {
    border-radius: 12px;
    padding: 30px 40px;
  }
}

.usps-block-with-icons ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (min-width: 769px) {
  .usps-block-with-icons ul {
    justify-content: space-between;
    display: flex;
  }

  .usps-block-with-icons li {
    flex-direction: column;
    width: 33.3333%;
    min-height: 100%;
    display: flex;
  }
}

@media (max-width: 768px) {
  .usps-block-with-icons li:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media (min-width: 769px) {
  .usps-block-with-icons li:not(:last-child) {
    margin-right: 4%;
  }
}

.usps-block-with-icons .usp-image {
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
  display: flex;
}

@media (min-width: 769px) {
  .usps-block-with-icons .usp-image {
    width: 48px;
    height: 48px;
  }
}

.usps-block-with-icons img {
  margin: 0 auto;
  display: block;
}

.usps-block-with-icons h3 {
  color: #000;
  max-width: 260px;
  margin: 0 auto 10px;
  font-size: 23px;
  font-weight: bold;
  line-height: 1.13;
}

.usps-block-with-icons p {
  color: #000;
  margin: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.31;
}

@media (min-width: 769px) {
  .usps-block-with-icons p:not(:last-child) {
    margin: 0 0 10px;
  }
}

.section-exclusive-offers-list {
  padding: 0 0 50px;
}

@media (min-width: 769px) {
  .section-exclusive-offers-list {
    padding: 0 0 80px;
  }
}

.section-exclusive-offers-list :first-child {
  margin-top: 0;
}

.section-exclusive-offers-list :last-child {
  margin-bottom: 0;
}

.section-exclusive-offers-list .preview-link {
  color: gray;
  margin-bottom: .2rem;
  font-size: .625rem;
  text-decoration: none;
}

.offers-count {
  text-transform: uppercase;
  color: #000;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.88;
}

.offers-count:before {
  content: "";
  vertical-align: middle;
  background: #c1d3ee;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 7px 0 0;
  display: inline-block;
}

.filters-line {
  margin: 0 0 40px;
  display: flex;
}

@media (min-width: 769px) {
  .filters-line {
    margin: 0 0 60px;
  }
}

.filters-line label {
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 700;
}

.filters-list {
  flex-shrink: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media (max-width: 768px) {
  .filters-list {
    margin: 0;
    padding: 0;
    display: block;
  }
}

.filters-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.filters-list::-webkit-scrollbar-track {
  background-color: #fff;
}

.filters-list::-webkit-scrollbar-thumb {
  background-color: #1c69d4;
  border: 3px solid #0000;
  border-radius: 6px;
}

.filters-list::-webkit-scrollbar {
  background-clip: padding-box;
  border: 0;
  border-radius: 13px;
  width: 5px;
  height: 5px;
}

.filters-list::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 0;
  border-radius: 13px;
  width: 5px;
  height: 5px;
}

.filter {
  flex-direction: column;
  display: flex;
}

@media (max-width: 768px) {
  .filter {
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) {
  .filter {
    width: 25%;
  }
}

.filter label {
  margin-bottom: 10px;
}

.filter select {
  color: #666;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("select-arrow.b1a5ece9.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  border: 1px solid #ccc;
  width: 230px;
  max-width: 100%;
  padding: 14px 30px 14px 14px;
  font-size: 16px;
  font-weight: 300;
}

.filter select.has-selection {
  color: #000;
  font-weight: 700;
}

@media (max-width: 768px) {
  .filter select {
    width: 100%;
  }
}

.filter option {
  font-weight: 300;
}

.view-toggler label {
  padding-left: 12px;
}

.view-toggler-list {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0 -12px 0 0 !important;
}

.toggle-view-btn {
  cursor: pointer;
  background: 0;
  border: 0;
  margin: 0;
  padding: 12px;
}

.toggle-view-btn rect {
  fill: #ecf1fa;
}

.toggle-view-btn.active {
  pointer-events: none;
}

.toggle-view-btn.active rect, .toggle-view-btn:hover rect, .toggle-view-btn:focus rect {
  fill: #c2d3ee;
}

.vehicle-list {
  flex-wrap: wrap;
  margin: -14px -14px 40px;
  padding: 0;
  list-style: none;
  display: flex;
}

.vehicle {
  flex-direction: column;
  padding: 14px;
  display: flex;
}

.view-mode-grid .vehicle {
  width: 100%;
}

@media (min-width: 769px) {
  .view-mode-grid .vehicle {
    width: 50%;
  }

  .view-mode-list .vehicle {
    flex-direction: row;
    width: 100%;
  }
}

.vehicle-description {
  margin: 0 0 25px;
}

@media (min-width: 769px) {
  .vehicle-description {
    flex-grow: 1;
  }

  .view-mode-list .vehicle-description {
    align-items: center;
    display: flex;
  }
}

.vehicle-description p {
  margin: 0;
  font-size: 14px;
}

.vehicle-description .vehicle-rent {
  border-left: 1px solid #dfdfe0;
  margin-left: 22px;
  padding-left: 22px;
}

.view-mode-grid .vehicle-description .vehicle-rent {
  display: none;
}

@media (max-width: 768px) {
  .view-mode-list .vehicle-description .vehicle-rent {
    display: none;
  }
}

@media (min-width: 769px) {
  .view-mode-list .vehicle-description-content {
    flex-shrink: 1;
    width: 100%;
  }
}

.vehicle-img {
  background-image: url("car-bg.cffd96df.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 10%;
  position: relative;
}

@media (min-width: 769px) {
  .vehicle-img {
    padding-top: 2%;
  }

  .view-mode-list .vehicle-img {
    flex-shrink: 0;
    width: 41%;
    margin-right: 10px;
    padding-top: 1%;
  }
}

.vehicle-img img {
  max-width: 97%;
  margin: 0 auto;
}

.pin-new {
  letter-spacing: .11px;
  color: #fff;
  text-align: center;
  background: #1d1d1d;
  border-radius: 10px;
  width: 60px;
  height: 20px;
  font-size: 8px;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (min-width: 769px) {
  .view-mode-list .vehicle-content-list {
    background: #f5f5f5;
    border-radius: 12px;
    flex-direction: column;
    padding: 18px 23px 18px 26px;
    display: flex;
  }
}

.vehicle-title-line {
  border-bottom: 1px solid #e4eaee;
  align-items: center;
  min-height: 80px;
  margin-bottom: 9px;
  padding-bottom: 8px;
  display: flex;
}

@media (min-width: 769px) {
  .vehicle-title-line {
    flex-grow: 1;
  }

  .view-mode-list .vehicle-title-line {
    border: 0;
    min-height: 0;
    margin: 0;
    padding: 0;
  }
}

.vehicle-title-line h3 {
  color: #1b2125;
  flex-shrink: 1;
  width: 100%;
  margin: 0;
  padding-right: 5px;
  font-size: 28px;
}

@media (min-width: 769px) {
  .view-mode-list .vehicle-title-line h3 {
    text-align: center;
    margin: 0 0 20px;
    font-size: 34px;
  }

  .view-mode-list .vehicle-title-line .vehicle-rent {
    display: none;
  }
}

.vehicle-rent {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
}

.vehicle-rent small {
  font-size: 10px;
}

@media (min-width: 769px) {
  .view-mode-list .vehicle-rent small {
    font-size: 14px;
  }
}

.vehicle-rent > b {
  font-size: 28px;
  line-height: .9;
}

@media (min-width: 769px) {
  .view-mode-list .vehicle-rent > b {
    font-size: 31px;
  }
}

.vehicle-rent span {
  font-size: 10px;
}

@media (min-width: 769px) {
  .view-mode-list .vehicle-rent span {
    font-size: 11px;
  }
}

.vehicle-rent span:before {
  content: "•";
  color: #c2d3ee;
  margin: 0 4px 0 0;
  display: inline;
}

.vehicle-rent sup {
  font-size: 75%;
  position: relative;
  top: 1px;
}

.vehicle-subtitles {
  flex-wrap: wrap;
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
  display: flex;
}

.vehicle-subtitles li {
  font-size: 14px;
  font-weight: 700;
}

.vehicle-subtitles li:not(:last-child):after {
  content: "•";
  margin: 0 4px;
  display: inline;
}

.empty-vehicle-results {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.empty-vehicle-results p {
  margin-bottom: 1rem;
}

.hubsf-b2b .offers-count:before {
  background: #182f4e;
}

.hubsf-b2b .vehicle-img {
  background-image: url("car-bg-b2b.d9c6b242.png");
}

.hubsf-b2b .toggle-view-btn.active rect, .hubsf-b2b .toggle-view-btn:hover rect, .hubsf-b2b .toggle-view-btn:focus rect {
  fill: #182f4e;
}

.hubsf-b2b .vehicle-rent span:before {
  color: #182f4e;
}

.hubsf-b2b .hubsf-legals {
  background-color: #f2f3f3;
}

.section-exclusive-offers-showroom > .container > h2 {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: .625rem;
}

.section-exclusive-offers-showroom .exclusive-offers-showroom-offer {
  display: none;
  position: relative;
}

.section-exclusive-offers-showroom .exclusive-offers-showroom-offer.current {
  display: block;
}

.section-exclusive-offers-showroom .exclusive-offers-showroom-offer-title h2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .exclusive-offers-showroom-offer-title h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
}

.section-exclusive-offers-showroom .exclusive-offers-showroom-offer-title h2 span {
  font-size: 1rem;
  font-weight: 300;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .exclusive-offers-showroom-offer-title {
    z-index: 2;
    position: absolute;
  }

  .section-exclusive-offers-showroom .exclusive-offers-showroom-offer-title .exclusive-offer-switcher {
    position: static;
  }
}

.section-exclusive-offers-showroom .exclusive-offer-container {
  background-image: linear-gradient(#cad6f2c7 162%, #ecf2fbc7 -36%);
  border-radius: .625rem;
  padding: 1.5rem 2.18rem 5rem 1.25rem;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .exclusive-offer-container {
    background: none;
    flex-direction: column;
    padding: 0;
    display: flex;
    position: relative;
  }
}

.section-exclusive-offers-showroom .exclusive-offer-switcher {
  text-align: center;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .exclusive-offer-switcher {
    text-align: left;
    z-index: 2;
    position: absolute;
  }
}

.section-exclusive-offers-showroom .exclusive-offer-switcher button {
  cursor: pointer;
  background-image: linear-gradient(183deg, #ecf2fb 134%, #c7d3e7 -19%);
  background-repeat: no-repeat;
  border: none;
  border-radius: 6px 6px 0 0;
  padding: .625rem;
  font-weight: bold;
}

.section-exclusive-offers-showroom .exclusive-offer-switcher button i {
  background-image: url("offer-switcher-icon.8987fea1.svg");
  width: 12px;
  height: 10px;
  margin-right: 5px;
  display: inline-block;
}

.section-exclusive-offers-showroom .exclusive-offer-link-zi-top {
  z-index: 2;
  height: 3rem;
  display: none;
  position: absolute;
  right: 1.56rem;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .exclusive-offer-link-zi-top {
    width: 33%;
    display: block;
    top: 133px;
  }
}

@media (min-width: 991px) {
  .section-exclusive-offers-showroom .exclusive-offer-link-zi-top {
    width: 27%;
  }
}

@media (min-width: 1280px) {
  .section-exclusive-offers-showroom .exclusive-offer-link-zi-top {
    width: 20%;
    top: 141px;
  }
}

.section-exclusive-offers-showroom .exclusive-offer-link-zi-top:hover + .showroom-rent .button-blue {
  background: #fff;
  color: #1c69d4 !important;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .showroom-rent {
    float: right;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-image: linear-gradient(149deg, #cad6f24d -18%, #cad6f2ee -18%, #ecf2fb30 117%), linear-gradient(234deg, #f0f6fdee 98%, #d0def575 -4%);
    border-radius: 10px;
    width: 40%;
    padding: 1.56rem;
    position: absolute;
    right: 0;
  }
}

@media (min-width: 991px) {
  .section-exclusive-offers-showroom .showroom-rent {
    width: 32%;
  }
}

@media (min-width: 1280px) {
  .section-exclusive-offers-showroom .showroom-rent {
    width: 24%;
    padding: 2.13rem 1.56rem;
  }
}

.section-exclusive-offers-showroom .showroom-rent .rent-title {
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
}

.section-exclusive-offers-showroom .showroom-rent .rent-title:before {
  content: "";
  background-color: #1d6ad4;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 7px 5px 3px 0;
  display: inline-block;
}

.section-exclusive-offers-showroom .showroom-rent .rent-price {
  text-align: center;
  margin: 1.1rem 0;
  font-size: 2.375rem;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .showroom-rent .rent-price {
    text-align: left;
  }
}

.section-exclusive-offers-showroom .showroom-rent .rent-price b {
  font-weight: bold;
}

.section-exclusive-offers-showroom .showroom-rent .rent-price sup {
  font-size: 1rem;
  position: relative;
  top: -12px;
}

.section-exclusive-offers-showroom .exclusive-offer-link a {
  border-radius: 2px;
  width: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.section-exclusive-offers-showroom .offer-usps-list {
  padding: 0;
}

.section-exclusive-offers-showroom .offer-usps-list ul {
  padding: 0 0 0 1.4rem;
}

.section-exclusive-offers-showroom .offer-usps-list ul li {
  border-bottom: 1px solid #2b548d15;
  padding: 1rem .2rem 1rem .5rem;
  list-style: none;
}

.section-exclusive-offers-showroom .offer-usps-list ul li span {
  text-align: left;
  background-image: url("usp-icon.63e53b45.svg");
  background-position: 0 3px;
  background-repeat: no-repeat;
  padding-left: 2rem;
  display: inline-block;
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .offer-usps-list {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-image: linear-gradient(195deg, #ecf3fccc 80%, #ecf2fbcc -42%);
    border-radius: 10px;
    order: 1;
  }

  .section-exclusive-offers-showroom .offer-usps-list ul {
    align-items: stretch;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    display: flex;
  }

  .section-exclusive-offers-showroom .offer-usps-list ul li {
    text-align: center;
    border: none;
    flex: 0 0 33.33%;
    margin-left: 0;
    padding-left: 1.5%;
    padding-right: 1.5%;
    font-size: 1.125rem;
    font-weight: 300;
  }
}

.section-exclusive-offers-showroom .exclusive-offer-slider {
  width: 360px;
  max-width: 100%;
  margin-top: -300px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  transform: translateY(83%);
}

@media (min-width: 576px) {
  .section-exclusive-offers-showroom .exclusive-offer-slider {
    margin-top: -50%;
  }
}

@media (min-width: 768px) {
  .section-exclusive-offers-showroom .exclusive-offer-slider {
    width: 75%;
    margin: 8% auto 0;
    padding-top: 0;
    padding-bottom: 1.625rem;
    transform: none;
  }
}

@media (min-width: 1280px) {
  .section-exclusive-offers-showroom .exclusive-offer-slider {
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 769px) {
  .section-exclusive-offers-showroom .glide {
    flex-direction: column;
    min-height: 100%;
    display: flex;
  }
}

.section-exclusive-offers-showroom .glide__bullets {
  justify-content: center;
  align-items: center;
  height: 15px;
  display: flex;
  position: static;
  bottom: 0;
  transform: none;
}

.section-exclusive-offers-showroom .glide__bullet {
  box-shadow: none;
  background: #a1a1a1;
  width: 8px;
  height: 8px;
  transition: all .3s linear;
  border: 0 !important;
}

.section-exclusive-offers-showroom .glide__bullet.glide__bullet--active {
  background: #000;
  border: 5px solid #000;
  width: 11px;
  height: 11px;
}

.section-exclusive-offers-showroom .glide__track {
  flex-grow: 1;
  margin-bottom: 40px;
}

.section-exclusive-offers-showroom .glide__slide img {
  margin: 0 auto;
}

.offers-slider {
  padding: 0 0 20px;
}

@media (min-width: 769px) {
  .offers-slider {
    padding: 0 0 10px;
  }
}

.offers-slider .header {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 54px;
  padding: 15px 0 8px;
  display: flex;
}

@media (max-width: 768px) {
  .offers-slider .header {
    flex-direction: column;
  }
}

.offers-slider .header .offers-count {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.offers-slider .header .offers-count .header-title {
  font-size: 32px;
  font-weight: 300;
}

.offers-slider .header .col-30 {
  align-self: self-end;
  height: 50px;
}

@media (max-width: 768px) {
  .offers-slider .header .col-30 {
    text-align: center;
    margin: 20px 0;
    padding-top: 25px;
  }
}

.offers-slider .texts-glide {
  margin: 0 0 65px;
}

@media (min-width: 769px) {
  .offers-slider .texts-glide {
    margin-bottom: 85px;
  }
}

.offers-slider .texts-glide .glide__track {
  z-index: 0;
  margin: -15px -15px 5px;
  position: relative;
}

@media (min-width: 769px) {
  .offers-slider .texts-glide .glide__track {
    border-radius: 23px;
  }
}

.offers-slider .texts-glide .glide__track:before {
  content: "";
  z-index: -1;
  border-radius: 15px;
  display: block;
  position: absolute;
  inset: 15px;
}

.offers-slider .texts-glide .glide__slides {
  overflow: visible;
}

@media (max-width: 768px) {
  .offers-slider .texts-glide .glide__slides {
    margin: 0;
  }
}

.offers-slider .texts-glide .glide__bullets {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 14px;
  margin-top: 50px;
  display: flex;
  position: relative;
  bottom: 0;
  left: 0;
  transform: none;
}

.offers-slider .texts-glide .glide__bullet {
  box-shadow: none;
  background: #d8d9da;
  border: 0;
  width: 10px;
  height: 10px;
  transition: all .1s linear;
  display: inline-block;
}

.offers-slider .texts-glide .glide__bullet:hover, .offers-slider .texts-glide .glide__bullet:focus, .offers-slider .texts-glide .glide__bullet.glide__bullet--active {
  width: 14px;
  height: 14px;
}

.offers-slider .texts-glide .glide__bullet.glide__bullet--active {
  background: #1c69d4;
}

.offers-slider .texts-glide p {
  text-align: left;
  margin: 10px 0 0;
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
}

.offers-slider .pin-img {
  text-align: center;
  width: 60px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/*# sourceMappingURL=bmw.css.map */
