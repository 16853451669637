// Typography --------------------------------

$font-main: "BMWGroup_Global", sans-serif;
$font-type-next-tt: "BMWTypeNextWeb", Arial, Helvetica, sans-serif;

// Colors -------------------------------------

$gap: 20px;
$duration: .3s;

// Color Variables (from BMW forms project)
$color-black    : #000000;
$color-black-lg : #262626;
$color-black-lgt: #3E3E3E;
$color-white-dk : #F2F2F2;
$color-white    : #FFFFFF;
$color-white-op : #FFFFFF29;

$color-grey-dkr : #262626;
$color-grey-dkrl: #4A4A4A;
$color-grey-dk  : #666666;
$color-grey-dkl : #949494;
$color-grey     : #bbbbbb;
$color-grey-op  : rgba(230, 230, 230, 0.39);
$color-grey-lg  : #E6E6E6;
$color-grey-lgt : #F8F8F8;

$color-gray : #4D4D4D;

$color-red      : #FF0000;
$color-blue     : #1C69D4;
$color-blue-op  : #1C69D44D;
$color-blue-dkl : #005AE1;
$color-blue-dk  : #0653B6;
$color-blue-lg  : #4A90E2;

// Color Variables added in this project
$color-black-af : #0d0d0d;
$color-black-af-2 : #1d1d1d;
$color-gray-af : #f2f5f7;
