@font-face {
  font-family: 'BMWGroup_Global';
  src: url('../fonts/bmw/BMWGroup_Global_Thin.woff2') format('woff2'),
  url('../fonts/bmw/BMWGroup_Global_Thin.woff') format('woff'),
  url('../fonts/bmw/BMWGroup_Global_Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BMWGroup_Global';
  src: url('../fonts/bmw/BMWGroup_Global_Light.woff2') format('woff2'),
  url('../fonts/bmw/BMWGroup_Global_Light.woff') format('woff'),
  url('../fonts/bmw/BMWGroup_Global_Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BMWGroup_Global';
  src: url('../fonts/bmw/BMWGroup_Global_Bold.woff2') format('woff2'),
  url('../fonts/bmw/BMWGroup_Global_Bold.woff') format('woff'),
  url('../fonts/bmw/BMWGroup_Global_Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'BMWTypeNextWeb';
  src: url('../fonts/bmw/BMWTypeNext-Thin.woff2') format('woff2'),
  url('../fonts/bmw/BMWTypeNextTT-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'BMWTypeNextWeb';
  src: url('../fonts/bmw/BMWTypeNext-Light.woff2') format('woff2'),
  url('../fonts/bmw/BMWTypeNextTT-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'BMWTypeNextWeb';
  src: url('../fonts/bmw/BMWTypeNext-Regular.woff2') format('woff2'),
  url('../fonts/bmw/BMWTypeNextTT-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BMWTypeNextWeb';
  src: url('../fonts/bmw/BMWTypeNext-Bold.woff2') format('woff2'),
  url('../fonts/bmw/BMWTypeNextTT-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
