*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

body,
p,
a,
input,
select,
textarea,
button,
ul,
ol,
li {
  font-family: $font-type-next-tt;
}

.container {
  margin: 0 auto;
  padding: 0 $gap;
  width: 100%;
  max-width: 1240px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
}

section {
  margin-bottom: 40px;

  @media (min-width: 769px) {
    margin-bottom: 90px;
  }
}

[id^="section-"] {
  //scroll-margin-top: 73px;

  @media (min-width: 769px) {
    //scroll-margin-top: 20px;
  }
}

.section-black {
  color: $color-white;
  background: $color-black-af;
}

.section-beige {
  background: #EEE4D4;
}

.section-gray {
  background: $color-gray-af;
}

.section-gray-light {
  background: #f5f6f7;
}

.section-gray-lg {
  background: $color-grey-lg;
}

.section-blue-lg {
  background: #f4f8fb;
}

.section-gray-dark {
  background: $color-black-af-2;
}

.section-radial-dark {
  background-image: radial-gradient(circle at 44% 83%, #2a5d3a, #000, #616971, #69727b 0%, #152320 99%, #30312b 99%);
}

.s-padding-50 {
  padding: 30px 0;

  @media (min-width: 769px) {
    padding: 50px 0;
  }
}

.s-padding-60 {
  padding: 40px 0;

  &:first-child {
    padding-top: 0;
  }
  & + .s-padding-60 {
    padding-bottom: 0;
  }

  @media (min-width: 769px) {
    padding: 60px 0;
  }
}

.s-padding-70 {
  padding: 40px 0;

  @media (min-width: 769px) {
    padding: 70px 0;
  }
}

.s-padding-80 {
  padding: 50px 0;

  @media (min-width: 769px) {
    padding: 80px 0;
  }
}

.s-padding-90 {
  padding: 50px 0;

  @media (min-width: 769px) {
    padding: 90px 0;
  }
}

.s-padding-100 {
  padding: 60px 0;

  @media (min-width: 769px) {
    padding: 100px 0;
  }
}

.text-light {
  font-weight: 300;
}

.text-black {
  color: $color-black;
}

.text-white {
  color: $color-white;
}

.h2-center h2 {
  text-align: center;
}

.h2-right h2 {
  text-align: right;
}

.h2-33 {

  h2 {
    font-weight: 300;
    line-height: 1.29;
    margin: 0 0 32px;
    font-size: 24px;
    letter-spacing: 0.5px;

    @media (min-width: 769px) {
      font-size: 33px;
      letter-spacing: 0.8px;
    }
  }
}

.h3-24 {

  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 0.96;
  }
}

.h2-uppercase {

  h2 {
    text-transform: uppercase;
  }
}

.button-center {
  text-align: center;
}

.button-left {
  text-align: left;
}

.button-right {
  text-align: right;
}

.nm {
  margin: 0;
}

.f-xl {
  font-size: 36px;
  line-height: 1;
  //font-weight: 700;
  text-transform: none;

  @media (min-width: 769px) {
    //font-size: 77px;
  }
}

.f-xl-subtitle {
  font-size: 18px;
  margin: 0 0 30px;

  @media (min-width: 769px) {
    font-size: 18px;
  }
}

.image-round img {
  border-radius: 6px;
}

.image-round-desktop img {

  @media (min-width: 769px) {
    border-radius: 6px;
  }
}

.image-round-mobile img {

  @media (max-width: 768px) {
    border-radius: 6px;
  }
}

.at-bottom {

  &::before,
  &::after {
    top: 100%;
  }

  &::after {
    border-radius: 20px 20px 0 0;

    @media (min-width: 1240px) {
      border-radius: 40px 40px 0 0;
    }
  }

  &.at-inner {
    padding-bottom: 40px;

    @media (min-width: 1240px) {
      padding-bottom: 80px;
    }
  }

  &.at-inner::before,
  &.at-inner::after {
    margin-top: -40px;

    @media (min-width: 1240px) {
      margin-top: -80px;
    }
  }

  &.at-inner::after {
    border-radius: 0 0 20px 20px;

    @media (min-width: 1240px) {
      border-radius: 0 0 40px 40px;
    }
  }
}

.at-top {

  &::before,
  &::after {
    bottom: 100%;
  }

  &::after {
    border-radius: 0 0 20px 20px;

    @media (min-width: 1240px) {
      border-radius: 0 0 40px 40px;
    }
  }

  &.at-inner {
    padding-top: 40px;

    @media (min-width: 1240px) {
      padding-top: 80px;
    }
  }

  &.at-inner::before,
  &.at-inner::after {
    margin-bottom: -40px;

    @media (min-width: 1240px) {
      margin-bottom: -80px;
    }
  }

  &.at-inner::after {
    border-radius: 20px 20px 0 0;

    @media (min-width: 1240px) {
      border-radius: 40px 40px 0 0;
    }
  }
}

.round-black,
.round-gray-light,
.round-gray-dark,
.round-white {
  position: relative;

  &::before,
  &::after {
    content: '';
    height: 40px;
    width: 100%;
    position: absolute;
    display: block;
    left: 0;

    @media (min-width: 1240px) {
      height: 80px;
    }
  }

  &::before {
    transition: background 0.4s ease-in-out;
  }
}

.round-black {

  &::before {
    background: $color-black-af;
  }

  &::after {
    background: $color-white;
  }
}

.round-white {

  &::before {
    background: $color-white;
  }

  &::after {
    background: $color-black-af;
  }
}

.round-gray-dark {

  &::before {
    background: $color-white;
  }

  &::after {
    background: $color-black-af-2;
  }
}

.round-gray-light {

  &::before {
    background: $color-white;
  }

  &::after {
    background: #f5f6f7;
  }
}

.bg-from-beige {

  &::before {
    background: #eee4d4;
  }
}

.only-desktop {

  @media (max-width: 768px) {
    display: none !important;
  }
}

.only-mobile {

  @media (min-width: 769px) {
    display: none !important;
  }
}

.hide {
  display: none;
}

.col-50 {
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.col-70 {
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.col-30 {
  width: 30%;

  @media (max-width: 768px) {
    width: 100%;
  }
}
