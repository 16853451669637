.icon-cars {
  content: url('../img/exclusive-offers/icon-cars.svg');
}
.icon-stats {
  content: url('../img/exclusive-offers/icon-stats.svg');
}
.icon-settings {
  content: url('../img/exclusive-offers/icon-settings.svg');
}
.icon-check {
  content: url('../img/exclusive-offers/check.svg');
}
.icon-plus-bg-white {
  content: url('../img/exclusive-offers/icon-plus-bg-white.svg');
}

