.offers-slider {
  padding: 0 0 20px;

  @media (min-width: 769px) {
    padding: 0 0 10px;
  }

  .header {
    display: flex;
    padding: 15px 0 8px 0;
    border-bottom: 1px solid #E6E6E6;
    margin-bottom: 54px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .offers-count{
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;

      .header-title {
        font-size: 32px;
        font-weight: 300;
      }
    }

    .col-30 {
      height: 50px;
      align-self: self-end;

      @media (max-width: 768px) {
        text-align: center;
        padding-top: 25px;
        margin: 20px 0;
      }
    }
  }

  .texts-glide {
    margin: 0 0 65px;

    @media (min-width: 769px) {
      margin-bottom: 85px;
    }

    .glide__track {
      margin: -15px -15px 5px;
      z-index: 0;
      position: relative;

      @media (min-width: 769px) {
        border-radius: 23px;
      }

      &::before {
        content: '';
        z-index: -1;
        border-radius: 15px;
        display: block;
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
      }
    }

    .glide__slides {
      overflow: visible;

      @media (max-width: 768px) {
        margin: 0;
      }
    }

    .glide__bullets {
      position: relative;
      bottom: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 14px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }

    .glide__bullet {
      display: inline-block;
      background: #D8D9DA;
      width: 10px;
      height: 10px;
      transition: all 100ms linear;
      box-shadow: none;
      border: 0;

      &:hover,
      &:focus,
      &.glide__bullet--active {
        width: 14px;
        height: 14px;
      }

      &.glide__bullet--active {
        background: $color-blue;
      }
    }

    p {
      font-size: 15px;
      font-weight: normal;
      text-align: left;
      line-height: normal;
      margin: 10px 0 0 0;
    }
  }

  .pin-img {
    width: 60px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
  }
}

