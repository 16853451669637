.section-exclusive-offers-list {
  padding: 0 0 50px;

  @media (min-width: 769px) {
    padding: 0 0 80px;
  }

  * {

    &:first-child {
      margin-top: 0;
    }
  }

  * {

    &:last-child {
      margin-bottom: 0;
    }
  }

  .preview-link {
    color: gray;
    text-decoration: none;
    font-size: 0.625rem;
    margin-bottom: 0.2rem;
  }
}

.exclusive-offers-list {
}

.offers-count {
  border-bottom: 1px solid $color-grey-lg;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.88;
  color: $color-black;
  margin-bottom: 10px;

  &::before {
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #c1d3ee;
    vertical-align: middle;
    margin: 0 7px 0 0;
  }
}

.filters-line {
  display: flex;
  margin: 0 0 40px;

  @media (min-width: 769px) {
    margin: 0 0 60px;
  }

  label {
    font-size: 16px;
    font-weight: 700;
    color: #1d1d1d;
  }
}

.filters-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  flex-shrink: 1;

  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    display: block;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-blue;
    border-radius: 6px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    height: 5px;
    width: 5px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 0;
  }
}

.filter {
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  @media (min-width: 769px) {
    width: 25%;
  }

  label {
    margin-bottom: 10px;
  }

  select {
    font-size: 16px;
    font-weight: 300;
    color: #666666;
    width: 230px;
    max-width: 100%;
    border: 1px solid #cccccc;
    padding: 14px 30px 14px 14px;
    appearance: none;
    -moz-appearance: none;
    background-image: url('../img/exclusive-offers/select-arrow.svg');
    background-position: right 10px center;
    background-size: 10px;
    background-repeat: no-repeat;

    &.has-selection {
      color: $color-black;
      font-weight: 700;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  option {
    font-weight: 300;
  }
}

.view-toggler {


  label {
    padding-left: 12px;
  }
}

.view-toggler-list {
  list-style: none;
  display: flex;
  margin: 0 -12px 0 0 !important;
  padding: 0;

  li {

  }
}

.toggle-view-btn {
  padding: 12px;
  margin: 0;
  border: 0;
  background: 0;
  cursor: pointer;

  rect {
    fill: #ECF1FA;
  }

  &.active {
    pointer-events: none;

    rect {
      fill: #C2D3EE;
    }
  }

  &:hover,
  &:focus {

    rect {
      fill: #C2D3EE;
    }
  }
}

.vehicle-list {
  list-style: none;
  margin: -14px -14px 40px -14px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.vehicle {
  padding: 14px;
  display: flex;
  flex-direction: column;

  .view-mode-grid & {
    width: 100%;

    @media (min-width: 769px) {
      width: 50%;
    }
  }

  .view-mode-list & {

    @media (min-width: 769px) {
      width: 100%;
      flex-direction: row;
    }
  }
}

.vehicle-description {
  margin: 0 0 25px;

  @media (min-width: 769px) {
    flex-grow: 1;
  }

  .view-mode-list & {

    @media (min-width: 769px) {
      display: flex;
      align-items: center;
    }
  }

  p {
    font-size: 14px;
    margin: 0;
  }

  .vehicle-rent {
    border-left: 1px solid #DFDFE0;
    padding-left: 22px;
    margin-left: 22px;

    .view-mode-grid & {
      display: none;
    }

    .view-mode-list & {

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}

.vehicle-description-content {

  .view-mode-list & {

    @media (min-width: 769px) {
      width: 100%;
      flex-shrink: 1;
    }
  }
}

.vehicle-img {
  background-image: url('../img/exclusive-offers/car-bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 10%;
  position: relative;

  @media (min-width: 769px) {
    padding-top: 2%;
  }

  .view-mode-list & {

    @media (min-width: 769px) {
      width: 41%;
      flex-shrink: 0;
      margin-right: 10px;
      padding-top: 1%;
    }
  }

  img {
    max-width: 97%;
    margin: 0 auto;

    @media (min-width: 769px) {
    }
  }
}

.pin-new {
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.11px;
  color: $color-white;
  width: 60px;
  height: 20px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: $color-black-af-2;
  text-align: center;
  line-height: 20px;
}

.vehicle-content-list {

  .view-mode-list & {

    @media (min-width: 769px) {
      background: #F5F5F5;
      border-radius: 12px;
      padding: 18px 23px 18px 26px;
      display: flex;
      flex-direction: column;
    }
  }
}

.vehicle-title-line {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 9px;
  border-bottom: 1px solid #e4eaee;
  min-height: 80px;

  @media (min-width: 769px) {
    flex-grow: 1;
  }

  .view-mode-list & {

    @media (min-width: 769px) {
      border: 0;
      padding: 0;
      margin: 0;
      min-height: 0;
    }
  }

  h3 {
    width: 100%;
    flex-shrink: 1;
    margin: 0;
    font-size: 28px;
    color: #1b2125;
    padding-right: 5px;

    .view-mode-list & {

      @media (min-width: 769px) {
        font-size: 34px;
        text-align: center;
        margin: 0 0 20px;
      }
    }
  }

  .vehicle-rent {

    .view-mode-list & {

      @media (min-width: 769px) {
        display: none;
      }
    }
  }
}

.vehicle-rent {
  flex-shrink: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;

  small {
    font-size: 10px;

    .view-mode-list & {

      @media (min-width: 769px) {
        font-size: 14px;
      }
    }
  }

  > b {
    font-size: 28px;
    line-height: 0.9;

    .view-mode-list & {

      @media (min-width: 769px) {
        font-size: 31px;
      }
    }
  }

  span {
    font-size: 10px;

    .view-mode-list & {

      @media (min-width: 769px) {
        font-size: 11px;
      }
    }

    &::before {
      content: '•';
      display: inline;
      margin: 0 4px 0 0;
      color: #c2d3ee;
    }
  }

  sup {
    font-size: 75%;
    position: relative;
    top: 1px;
  }
}

.vehicle-subtitles {
  list-style: none;
  margin: 0 0 5px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    font-size: 14px;
    font-weight: 700;

    &:not(:last-child) {

      &:after {
        content: '•';
        display: inline;
        margin: 0 4px;
      }
    }
  }
}
.empty-vehicle-results {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;

  p {
    margin-bottom: 1rem;
  }
}


.hubsf-b2b{
  .offers-count {
    &::before {
      background: #182F4E;
    }
  }
  .filter {
    /*select {
      width: 180px;
      font-size: 12px;
      color: #878787;
      border-color: #514D4D;
      padding: 5px 30px 5px 14px;
      background-color: #FFFFFF;
      border-radius: 4px;
      option{
        background-color: #FFFFFF;
      }
    }*/
  }
  .vehicle-img {
    background-image: url('../img/exclusive-offers/car-bg-b2b.png');
  }
  .toggle-view-btn {
    &.active {
      rect {
        fill: #182F4E;
      }
    }
    &:hover,
    &:focus {
      rect {
        fill: #182F4E;
      }
    }
  }

  .vehicle-rent {
    span {
      &::before {
        color: #182F4E;
      }
    }
  }

  .hubsf-legals{
    background-color: #F2F3F3;
    /*p{
      font-size: 12px;
    }*/
  }
}
