.section-exclusive-offers-showroom {

  & > .container > h2 {
    border-bottom: solid 1px #dcdcdc;
    padding-bottom: 0.625rem;
  }

  .exclusive-offers-showroom-offer {
    display: none;
    position: relative;

    &.current {
      display: block;
    }
  }
  .exclusive-offers-showroom-offer-title {
    h2 {
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1;
      @media (min-width: 769px) {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      span {
        font-weight: 300;
        font-size: 1rem;
      }
    }
    @media (min-width: 769px) {
      position: absolute;
      z-index: 2;

      .exclusive-offer-switcher {
        position: static;
      }
    }

  }

  .exclusive-offer-container {
    border-radius: 0.625rem;
    padding: 1.5rem 2.18rem 5rem 1.25rem;
    background-image: linear-gradient(to bottom, #cad6f2c7 162%, #ecf2fbc7 -36%);

    @media (min-width: 769px) {
      background: none;
      flex-direction: column;
      display: flex;
      position: relative;
      padding: 0;
    }
  }

  .exclusive-offer-switcher {
    text-align: center;
    @media (min-width: 769px) {
      text-align: left;
      position: absolute;
      z-index: 2;
    }

    button {
      cursor: pointer;
      background-repeat: no-repeat;
      font-weight: bold;
      border: none;
      padding: 0.625rem;
      border-radius: 6px 6px 0 0;
      background-image: linear-gradient(183deg, #ecf2fb 134%, #c7d3e7 -19%);
      i {
        display: inline-block;
        margin-right: 5px;
        width: 12px;
        height: 10px;
        background-image: url('../img/exclusive-offers/offer-switcher-icon.svg');
      }
    }
  }

  .exclusive-offer-link-zi-top {
    display: none;
    position: absolute;
    right: 1.56rem;
    height: 3rem;
    z-index: 2;
    @media (min-width: 769px) {
      display: block;
      width: 33%;
      top: 133px;
    }
    @media (min-width: 991px) {
      width: 27%;
    }
    @media (min-width: 1280px) {
      width: 20%;
      top: 141px;
    }


    &:hover + .showroom-rent .button-blue {
      background: #fff;
      color: #1c69d4 !important;
    }
  }

  .showroom-rent {
    @media (min-width: 769px) {
      width: 40%;
      padding: 1.56rem;
      float: right;
      border-radius: 10px;
      position: absolute;
      right: 0;

      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-image: linear-gradient(149deg, rgba(202, 214, 242, 0.3) -18%, #cad6f2ee -18%, rgba(236, 242, 251, 0.19) 117%), linear-gradient(234deg, #f0f6fdee 98%, rgba(208, 222, 245, 0.46) -4%);

    }

    @media (min-width: 991px) {
      width: 32%;
    }
    @media (min-width: 1280px) {
      width: 24%;
      padding: 2.13rem 1.56rem;
    }
    .rent-title {
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      &::before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        margin: 7px 5px 3px 0;
        border-radius: 50%;
        background-color: #1d6ad4;
      }
    }

    .rent-price {
      margin: 1.1rem 0;
      text-align: center;
      font-size: 2.375rem;
      @media (min-width: 769px) {
        text-align: left;
      }
      b {
        font-weight: bold;
      }
      sup {
        font-size: 1rem;
        position: relative;
        top: -12px;
      }
    }
  }

  .exclusive-offer-link {
    a {
      width: auto;
      border-radius: 2px;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
  }

  .offer-usps-list {
    padding: 0;

    ul {
      padding: 0 0 0 1.4rem;

      li {
        padding: 1rem 0.2rem 1rem 0.5rem;
        list-style: none;
        border-bottom: 1px solid #2b548d15;

        span {
          display: inline-block;
          background-repeat: no-repeat;
          background-image: url('../img/exclusive-offers/usp-icon.svg');
          background-position: top 3px left;
          padding-left: 2rem;
          text-align: left;
        }
      }
    }

    @media (min-width: 769px) {
      order: 1;
      backdrop-filter: blur(10px);
      background-image: linear-gradient(195deg, #ecf3fccc 80%, #ecf2fbcc -42%);
      border-radius: 10px;

      ul {
        display: flex;
        align-items: stretch;
        padding-left: 0;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        li {
          flex: 0 0 33.33%;
          margin-left: 0;
          border: none;
          font-size: 1.125rem;
          font-weight: 300;
          padding-left: 1.5%;
          padding-right: 1.5%;
          text-align: center;
        }
      }
    }

  }

  .exclusive-offer-slider {
    padding-top: 50px;
    width: 360px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -300px;
    transform: translateY(83%);

    @media (min-width: 576px) {
      margin-top: -50%;
    }
    @media (min-width: 768px) {
      transform: none;
      padding-top: 0;
      width: 75%;
      margin: 8% auto 0;
      padding-bottom: 1.625rem;
    }
    @media (min-width: 1280px) {
      width: 100%;
      margin: 0 auto;
    }

  }

  .glide {

    @media (min-width: 769px) {
      display: flex;
      flex-direction: column;
      min-height: 100%;

    }
  }

  .glide__bullets {
    display: flex;
    align-items: center;
    bottom: 0;
    position: static;
    transform: none;
    justify-content: center;
    height: 15px;
  }

  .glide__bullet {
    border: 0 !important;
    background: #a1a1a1;
    width: 8px;
    height: 8px;
    transition: all $duration linear;
    box-shadow: none;

    &.glide__bullet--active {
      border: 5px solid #000000;
      background: #000000;
      width: 11px;
      height: 11px;
    }
  }

  .glide__track {
    margin-bottom: 40px;
    flex-grow: 1;
  }

  .glide__slide {
    img {
      margin: 0 auto;
    }
  }

}
