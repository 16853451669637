.section-text {

  * {

    &:first-child {
      margin-top: 0;
    }
  }

  * {

    &:last-child {
      margin-bottom: 0;
    }
  }
}
