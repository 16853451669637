.section-usps-block-with-icons {
}

.usps-block-with-icons {
  background: #CFDCEB;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 -20px;
    padding: 30px 20px;
  }

  @media (min-width: 769px) {
    padding: 30px 40px;
    border-radius: 12px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (min-width: 769px) {
      display: flex;
      justify-content: space-between;
    }
  }

  li {

    @media (min-width: 769px) {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      width: 33.33334%;
    }

    &:not(:last-child) {

      @media (max-width: 768px) {
        margin-bottom: 40px;
      }

      @media (min-width: 769px) {
        margin-right: 4%;
      }
    }
  }

  .usp-image {
    margin: 0 auto 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 769px) {
      width: 48px;
      height: 48px;
    }
  }

  img {
    margin: 0 auto;
    display: block;
  }

  h3 {
    font-size: 23px;
    font-weight: bold;
    line-height: 1.13;
    color: $color-black;
    margin: 0 auto 10px;
    max-width: 260px;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.31;
    color: $color-black;
    margin: 0;

    &:not(:last-child) {

      @media (min-width: 769px) {
        margin: 0 0 10px;
      }
    }
  }
}
