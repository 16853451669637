@mixin button-block {
  padding: 13px 10px;
  box-shadow: none;
  border-radius: 0;
  font-size: 14px !important;
  font-weight: 700 !important;
  //text-transform: uppercase;
  text-decoration: none;
  line-height: 1.4;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  max-width: 300px;
  width: 100%;
  transition: all $duration linear;
  outline: none;
  -webkit-appearance: none;

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

@mixin button-block-blue {
  background: $color-blue;
  color: $color-white !important;
  border: 1px solid $color-blue;

  &:focus,
  &:hover {
    background: $color-white;
    color: $color-blue !important;
  }
}

@mixin button-block-hover-dark-blue {

  &:focus,
  &:hover {
    background: $color-blue-dk;
    color: $color-white !important;
    border: 1px solid $color-blue-dk;
  }
}

@mixin button-block-gray {
  background: $color-gray;
  color: $color-white !important;
  border: 1px solid $color-gray;

  &:focus,
  &:hover {
    color: $color-gray !important;
    background: $color-white;
  }
}

@mixin button-block-black-af {
  background: $color-black-af;
  color: $color-white !important;
  border: 1px solid $color-black-af;

  &:focus,
  &:hover {
    color: $color-black-af !important;
    background: $color-white;
  }
}

@mixin button-block-white-outline {
  background: transparent;
  color: $color-white !important;
  border: 2px solid $color-white;

  &:focus,
  &:hover {
    color: #8e8e8e !important;
    background: transparent;
    border: 2px solid #8e8e8e;
  }
}

@mixin button-block-black-outline {
  background: transparent;
  color: $color-black !important;
  border: 2px solid $color-black;

  &:focus,
  &:hover {
    color: #FFFFFF !important;
    background: $color-black;
    border: 2px solid $color-black;
  }
}

@mixin button-block-black-outline-thin {
  background: transparent;
  color: $color-black !important;
  border: 1px solid $color-black;

  &:focus,
  &:hover {
    color: $color-black !important;
    background: transparent;
    border: 1px solid $color-black;
    box-shadow: inset 1px 1px $color-black, inset -1px -1px $color-black;
  }
}